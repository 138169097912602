import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Boutique.css";

export default function Boutique() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Boutique";
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Découvrir mes articles</h1>
      <p className="txt-centered">
        Bievenue dans ma boutique, découvrez ici l'ensemble des offres que je
        propose! Bonne découverte.
      </p>
      <section className="tableau-perso">
        <div className="container-img-table">
          <img
            className="img-table"
            src={require("../../images/tableaux_personnalises_accueil.jpg")}
            alt="Tableau personnalisé réalisé par Flowarttag"
          />
        </div>
        <div className="text-perso">
          <p className="title-home">Tableaux personnalisés</p>
          <p className="txt-perso">
            Rendez vos souvenirs inoubliables, et laissez vous tenter par notre
            concept de tableau personnalisé.
          </p>
          <Link className="btn margin parallelogram" to="/tableaux">
            <span className="skew-fix">Voir plus</span>
          </Link>
        </div>
      </section>

      <section className="bombe-perso">
        <div className="text-bombe">
          <p className="title-home">Bombes personnalisées</p>
          <p className="txt-bombe">
            Optez pour l’originalité avec nos supports de bombes, pour un cadeau
            personnalisé.
          </p>
          <Link className="btn-black margin parallelogram-black" to="/bombes">
            <span className="skew-fix">Voir plus</span>
          </Link>
        </div>
        <div className="container-img-bombe">
          <img
            id="bombe-img"
            src={require("../../images/bombes_avant_personnalisees_accueil.png")}
            alt="Bombe personnalisée pour un cadeau réalisée par Flowarttag"
          />
        </div>
      </section>

      <section className="deco sticky-footer">
        <div className="container-img-deco">
          <img
            className="img-deco"
            src={require("../../images/articles_non_personnalises_accueil.jpg")}
            alt="Articles non personnalisés originaux et faits main"
          />
        </div>
        <div className="container-text-deco">
          <div className="text-deco">
            <p className="title-home">Juste pour décorer</p>
            <p className="txt-deco">
              Vous n’avez pas d’idée de cadeau? Vous souhaitez simplement
              trouver une idée de cadeau originale? Nous avons ce qu’il vous
              faut!
            </p>
            <Link className="btn margin parallelogram" to="/pour_decorer">
              <span className="skew-fix">Voir plus</span>
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
