import React, { useEffect, useState } from "react";
import "./Cart.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useLocalStorage } from "../../middlewares/localStorage";
import { createInvoice, verifyIsInvoiceIsPaid } from "../../api/payments";
import { getTableauxPrice, getBombesPrice } from "../../middlewares/getPrices";
import AlertBox from "../../components/AlertBox/AlertBox";
import {
  getUncustomizedArticle,
  getArticleAvailability,
} from "../../api/products";
import CartArticleCheckout from "../../components/CartArticleCheckout/CartArticleCheckout";
import PopUpInformationCheckout from "../../components/PopUpInformationCheckout/PopUpInformationCheckout";
import { Link } from "react-router-dom";

export default function Cart() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Mon panier";
  }, []);
  const [paymentIntentId, setPaymentIntentId] = useLocalStorage("invoiceId", {
    id: "",
  });
  const [isPopupNoArticleOpen, setIsPopupNoArticleOpen] = useState(false);
  const [isPopupErrorOpen, setIsPopupErrorOpen] = useState(false);
  const [isPopupCheckoutOpen, setIsPopupCheckoutOpen] = useState(false);
  const [isCGVPopupOpen, setIsCGVPopupOpen] = useState(false);
  const [isCGVChecked, setIsCGVChecked] = useState(false);

  const [Articles, setArticles] = useLocalStorage("cartContent", {
    paintings: [],
    sprayPaints: [],
    uncustomizedArticles: [],
  });

  const [overallPrice, setOverallPrice] = useState(0);
  const [numberOfArticles, setNumberOfArticles] = useState(0);
  const [articlesArray, setArticlesArray] = useState([]);

  const [toSend, setToSend] = useState({
    articles: [],
    oldInvoiceId: "",
    clientInformations: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getArticleAvailability("");
        if (res.status === 200) {
          for (const painting of Articles.paintings) {
            const paitingRatioQuantity = res.data.items.find(
              (item) => item.id === painting.format
            ).quantity;
            if (paitingRatioQuantity === 0) {
              const existingArticles = { ...Articles };
              existingArticles.paintings = existingArticles.paintings.filter(
                (item) => item.format !== painting.format
              );
              setArticles(existingArticles);
              window.location.reload();
            }
          }
          // eslint-disable-next-line
          for (const sprayPaint of Articles.sprayPaints) {
            const sprayPaintQuantity = res.data.items.find(
              (item) => item.id === "bombes"
            ).quantity;
            if (sprayPaintQuantity === 0) {
              const existingArticles = { ...Articles };
              console.log(existingArticles);
              existingArticles.sprayPaints =
                existingArticles.sprayPaints.filter(
                  (item) => item.type !== "bombes"
                );
              setArticles(existingArticles);
              window.location.reload();
            }
          }

          for (const uncustomizedArticle of Articles.uncustomizedArticles) {
            await getUncustomizedArticle(String(uncustomizedArticle)).then(
              (res) => {
                if (res.status === 200) {
                  if (res.data.articles.quantity === 0) {
                    const existingArticles = { ...Articles };
                    existingArticles.uncustomizedArticles =
                      existingArticles.uncustomizedArticles.filter(
                        (item) => item !== uncustomizedArticle
                      );
                    setArticles(existingArticles);
                    window.location.reload();
                  }
                }
              }
            );
          }
        }
      } catch (error) {
        console.error("Error fetching contact demands:", error);
      }
    };

    const initializeData = async () => {
      await fetchData();
      getOverallPrice();
      getNumberofArticles();
      createArticlesArray();
    };

    initializeData();
    // eslint-disable-next-line
  }, []);

  const getOverallPrice = async () => {
    let totalPrice = 0;

    for (const painting of Articles.paintings) {
      totalPrice += getTableauxPrice(painting);
    }

    for (const sprayPaint of Articles.sprayPaints) {
      totalPrice += getBombesPrice(sprayPaint);
    }

    for (const uncustomizedArticle of Articles.uncustomizedArticles) {
      const uncustomizedArticleData = await getUncustomizedArticle(
        uncustomizedArticle
      );
      const data = uncustomizedArticleData.data.articles;
      totalPrice += data.price;
    }
    if (totalPrice !== 0) {
      totalPrice += 4.99;
    }
    const roundedPrice = Math.round(totalPrice * 100) / 100;
    setOverallPrice(roundedPrice.toFixed(2));
  };

  const getNumberofArticles = () => {
    setNumberOfArticles(
      Articles.paintings.length +
        Articles.sprayPaints.length +
        Articles.uncustomizedArticles.length
    );
  };

  const createArticlesArray = async () => {
    let newArray = [];

    for (const painting of Articles.paintings) {
      painting.type = "tableaux";
      newArray.push(painting);
    }

    for (const sprayPaint of Articles.sprayPaints) {
      sprayPaint.type = "bombes";
      newArray.push(sprayPaint);
    }

    for (const uncustomizedArticle of Articles.uncustomizedArticles) {
      const uncustomizedArticleData = await getUncustomizedArticle(
        uncustomizedArticle
      );
      const data = uncustomizedArticleData.data.articles;
      data.type = "uncustomized";
      newArray.push(data);
    }

    setArticlesArray(newArray);
  };

  // Add this function inside your Cart component
  const removeArticle = (type, format, id) => {
    const updatedArticles = { ...Articles };

    switch (type) {
      case "tableaux":
        updatedArticles.paintings = updatedArticles.paintings.filter(
          (item) => item.format !== format
        );
        break;
      case "bombes":
        updatedArticles.sprayPaints = updatedArticles.sprayPaints.filter(
          (item) => item.type !== type
        );
        break;
      case "uncustomized":
        updatedArticles.uncustomizedArticles =
          updatedArticles.uncustomizedArticles.filter((item) => item !== id);
        break;
      default:
        break;
    }

    // Update the state
    setArticles(updatedArticles);

    // Update localStorage
    localStorage.setItem("cartContent", JSON.stringify(updatedArticles));
    window.location.reload();
  };

  const togglePopUp = () => {
    if (!isCGVChecked) {
      setIsCGVPopupOpen(true);
      setTimeout(() => {
        setIsCGVPopupOpen(false);
      }, 7000);
      return;
    } else if (numberOfArticles === 0 || articlesArray.length === 0) {
      setIsPopupNoArticleOpen(true);
      setTimeout(() => {
        setIsPopupNoArticleOpen(false);
      }, 7000);
      return;
    } else {
      setIsPopupCheckoutOpen(!isPopupCheckoutOpen);
    }
  };

  const handleClientInfoChange = (fieldName, value) => {
    setToSend((prevToSend) => ({
      ...prevToSend,
      clientInformations: {
        ...prevToSend.clientInformations,
        [fieldName]: value,
      },
    }));
  };

  const openPaymentInPopup = (url) => {
    // console.log(url);
    // const popupWindow = window.open(
    //   String(url),
    //   "Paiement de votre facture",
    //   "width=600,height=400"
    // );
    // if (
    //   !popupWindow ||
    //   popupWindow.closed ||
    //   typeof popupWindow.closed === "undefined"
    // ) {
    //   alert(
    //     "Popup blocked. Please disable your popup blocker or allow popups for this site."
    //   );
    // }

    // Instead of opening a popup, could you open a new tab?
    //Check user agent iphone or ipad
    const isIphone = navigator.userAgent.match(/iPhone/i);
    const isIpad = navigator.userAgent.match(/iPad/i);
    const isAndroid = navigator.userAgent.match(/Android/i);
    if (isIphone || isIpad || isAndroid) {
      //Change the client url to the url provided  by API
      window.location.href = url;
    } else {
      window.open(url, "_blank");
    }
  };

  const handlePopUpSubmission = async () => {
    if (numberOfArticles === 0 || articlesArray.length === 0) {
      setIsPopupNoArticleOpen(true);
      setTimeout(() => {
        setIsPopupNoArticleOpen(false);
      }, 7000);
      return;
    }

    // Include the entire toSend object in the request
    const requestData = {
      ...toSend,
      articles: articlesArray,
      oldInvoiceId: paymentIntentId.id,
    };

    await setToSend(requestData);

    createInvoice(requestData).then((res) => {
      if (res.status === 200) {
        const responseData = res.data;
        console.log(responseData);
        setPaymentIntentId({ id: responseData.newInvoiceId });
        setIsPopupCheckoutOpen(false);
        openPaymentInPopup(String(responseData.invoice.hosted_invoice_url));
      } else {
        setIsPopupErrorOpen(true);
        setTimeout(() => {
          setIsPopupErrorOpen(false);
        }, 7000);
      }
    });
  };

  useEffect(() => {
    const verifyCurrentInvoicePaid = async () => {
      if (paymentIntentId.id && paymentIntentId.id !== "undefined") {
        console.log(paymentIntentId.id);
        verifyIsInvoiceIsPaid(paymentIntentId.id).then((res) => {
          if (res.status === 200) {
            if (res.data.paid) {
              console.log("Invoice paid");
              setArticles({
                paintings: [],
                sprayPaints: [],
                uncustomizedArticles: [],
              });
              localStorage.removeItem("cartContent");
              localStorage.removeItem("invoiceId");
              window.location.href = "/";
              return;
            }
          }
        });
      }
    };

    // Set the interval (in milliseconds)
    const intervalId = setInterval(verifyCurrentInvoicePaid, 500); // Execute every 1000 milliseconds (1 second)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navbar />
      <div className="cart-page">
        <h1 className="title-shop">Mon panier</h1>
        <div className="cart-container">
          {articlesArray.length === 0 && (
            <div className="cart-empty">
              <h2>Votre panier est vide</h2>
              <p>
                Vous n'avez pas encore d'articles dans votre panier. Commencez
                par choisir un article dans la{" "}
                <Link className="link-legal" to="/shop">
                  boutique
                </Link>
              </p>
            </div>
          )}
          {articlesArray.length > 0 && (
            <div className="cart-articles">
              {articlesArray.map((article, index) => (
                <CartArticleCheckout
                  key={index}
                  articleData={article}
                  index={index}
                  length={articlesArray.length}
                  removeArticle={removeArticle} // Pass the function as a prop
                />
              ))}
            </div>
          )}

          <div className="cart-checkout">
            <h3 className="summary-cart">En résumé</h3>
            <div className="summary-cart-field">
              <p>Nombre d'articles:</p>
              <span>{numberOfArticles}</span>
            </div>
            <div className="summary-cart-field">
              <p>Sous-total (frais de ports de 4.99 inclus):</p>
              <span>{overallPrice} €</span>
            </div>

            <div className="cart-acceptance">
              <label htmlFor="cgv">
                J'ai lu et j'accepte les{" "}
                <Link className="link-legal" href="/cgv" target="_blank">
                  conditions générales de vente
                </Link>{" "}
                et les{" "}
                <Link className="link-legal" href="/cgu" target="_blank">
                  conditions générales d'utilisation
                </Link>
              </label>
              <input
                type="checkbox"
                id="cgv"
                name="cgv"
                onChange={() => setIsCGVChecked(!isCGVChecked)}
              />
            </div>

            <button
              className="btn-black margin parallelogram-black pointer-cursor"
              onClick={togglePopUp}
            >
              <span className="skew-fix">Payer</span>
            </button>
          </div>
        </div>
        {isPopupErrorOpen && (
          <AlertBox
            className="popup-error-while-creation"
            displayedMessage={
              "Une erreur est survenue, veuillez réessayer plus tard"
            }
          />
        )}
        {isPopupNoArticleOpen && (
          <AlertBox
            className="popup-error-no-article"
            displayedMessage={
              "Vous devez avoir au moins un article dans votre panier."
            }
          />
        )}
        {isCGVPopupOpen && (
          <AlertBox
            className="popup-cgv"
            displayedMessage={
              "Vous devez accepter les conditions générales de vente pour continuer."
            }
          />
        )}
        <PopUpInformationCheckout
          isOpen={isPopupCheckoutOpen}
          closePopUp={togglePopUp}
          onSubmit={handlePopUpSubmission}
          onChange={handleClientInfoChange}
        />
      </div>
      <Footer />
    </>
  );
}
