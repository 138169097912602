import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarousselItem from "./CarousselItem";
import { getCarrousselSlides } from "../../api/data";

export default function HomeCarousel() {
  const [slides, setSlides] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCarrousselSlides();
        if (res.status === 200) {
          setSlides(res.data.slides);
          setImages(res.data.images);
          console.log(res.data);
        }
      } catch (error) {
        console.error("Error fetching requested resource", error);
      }
    };

    fetchData();
  }, []);

  console.log(slides, images);

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings} className="carousel-provider">
      {slides.map((slide, index) => (
        <CarousselItem
          key={index}
          text={String(slide.description)}
          title={String(slide.title)}
          imageContent={images[index].content}
          link={String(slide.link)}
          backgroundColor={String(slide.backgroundColor)}
        />
      ))}
    </Slider>
  );
}
