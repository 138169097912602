import React, { useState, useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "./PopUpInformationCheckout.css";

const PopUpInformationCheckout = ({
  isOpen,
  closePopUp,
  onSubmit,
  onChange,
}) => {
  const popUpRef = useRef(null);

  const [clientObject, setClientObject] = useState({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postalCode: "",
  });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [PopUpLoadingAfterSubmission, setPopUpLoadingAfterSubmission] =
    useState(false);

  const handleChange = (e) => {
    setClientObject({ ...clientObject, [e.target.id]: e.target.value });
    onChange(e.target.id, e.target.value);
  };

  const handleSubmit = async () => {
    setPopUpLoadingAfterSubmission(true);
    onSubmit(clientObject);
    setTimeout(() => {
      setPopUpLoadingAfterSubmission(false);
      closePopUp();
    }, 7000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popUpRef.current &&
        !popUpRef.current.contains(event.target)
      ) {
        setPopUpLoadingAfterSubmission(false);
        closePopUp();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, closePopUp]);

  return (
    <div
      className={`cart-box-incomplete-fields-container ${
        isOpen ? "" : "pop-up-not-displayed"
      }`}
    >
      <div className={`cart-box-incomplete-fields`} ref={popUpRef}>
        {!PopUpLoadingAfterSubmission && (
          <>
            <IoClose
              className="close-popup-x pointer-cursor"
              onClick={closePopUp}
            />
            <h2 className={`cart-box-text`}>
              Avant tout, nous avons besoin de quelques informations:
            </h2>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="other">
                <label className="text-left-align">Votre prénom :</label>
                <input
                  name="firstName"
                  id="firstName"
                  placeholder="Pierre"
                  className="message-placeholder correction-message"
                  onChange={handleChange}
                />
              </div>
              <div className="other">
                <label className="text-left-align">Votre nom :</label>
                <input
                  name="lastName"
                  id="lastName"
                  placeholder="DUPONT"
                  className="message-placeholder correction-message"
                  onChange={handleChange}
                />
              </div>
              <div className="other">
                <label className="text-left-align">Votre e-mail :</label>
                <input
                  name="email"
                  id="email"
                  placeholder="pierre.dupont@example.com"
                  className="message-placeholder correction-message"
                  onChange={handleChange}
                />
              </div>
              <div className="other">
                <label className="text-left-align">Votre adresse :</label>
                <input
                  name="address"
                  id="address"
                  placeholder="1 rue de la Paix"
                  className="message-placeholder correction-message"
                  onChange={handleChange}
                />
              </div>

              <div className="other">
                <label className="text-left-align">Votre ville :</label>
                <input
                  name="city"
                  id="city"
                  placeholder="Paris"
                  className="message-placeholder correction-message"
                  onChange={handleChange}
                />
              </div>
              <div className="other">
                <label className="text-left-align">Votre code postal :</label>
                <input
                  name="postalCode"
                  id="postalCode"
                  placeholder="75000"
                  className="message-placeholder correction-message"
                  onChange={handleChange}
                />
              </div>
            </form>

            <div className={`button-popup-container margin-top-button`}>
              <button
                className="btn-black margin parallelogram-black pointer-cursor"
                onClick={handleSubmit}
              >
                <span className="skew-fix">Continuer le paiement</span>
              </button>
            </div>
          </>
        )}
        {PopUpLoadingAfterSubmission && (
          <>
            <ClipLoader
              css={override}
              size={150}
              color={"#123abc"}
              loading={true}
            />
            <p>
              Nous sommes en train de générer. Celle-ci devrait s'ouvrir dans
              une nouvelle fenêtre dans quelques secondes.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PopUpInformationCheckout;
