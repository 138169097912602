import React from "react";
import Skeleton from "react-loading-skeleton";

const ArticleNoPersoCardSkeleton = () => {
  return (
    <div className="article-item">
      <div className="article-image">
        <Skeleton height={200} width={200} />
      </div>
      <div className="article-content">
        <h3><Skeleton width={100} /></h3>
        <p><Skeleton count={3} /></p>
        <div className="btn-unpersonalized-articles-container">
          <Skeleton width={100} height={30} />
        </div>
      </div>
    </div>
  );
};

export default ArticleNoPersoCardSkeleton;
