import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiHomeAlt, BiShoppingBag, BiHelpCircle, BiBookOpen } from "react-icons/bi";
import { FaTimes, FaChevronDown, FaRegCommentDots } from "react-icons/fa";
import { PiPaintBrushBroadBold } from "react-icons/pi";
import { TfiSpray } from "react-icons/tfi";
import { IoSparklesOutline } from "react-icons/io5";
import { GoGift } from "react-icons/go";
import "./Sidebar.css";

const Sidebar = ({ isOpen, closeMenu }) => {
  const [boutiqueOpen, setBoutiqueOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleBoutiqueDropdown = () => {
    setBoutiqueOpen(!boutiqueOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, closeMenu]);

  return (
    <aside ref={sidebarRef} className={`sidebar ${isOpen ? "active" : ""}`}>
      <div className="sidebar-header">
        <img src={require("../../images/logo.png")} width="50" alt="" />
        <button className="close-btn" onClick={closeMenu}>
          <FaTimes />
        </button>
      </div>
      <ul className="sidebar-links">
        <li>
          <Link to="/" onClick={closeMenu}>
            <div className="sidebar-link-wrapper">
              <BiHomeAlt className="sidebar-icon" />
              <p>•</p>
              <p>Accueil</p>
            </div>
          </Link>
        </li>
        {/* Boutique et sub-menu */}
        <li>
          <div className="sidebar-link-wrapper">
            <Link to="/shop" onClick={closeMenu}>
              <div className="sidebar-link-wrapper no-padding">
                <BiShoppingBag className="sidebar-icon" />
                <p>•</p>
                <p>Boutique</p>
              </div>
            </Link>
            <button
              className="dropdown-toggle"
              onClick={toggleBoutiqueDropdown}
            >
              <FaChevronDown
                className={`chevron-icon ${boutiqueOpen ? "open" : "close"}`}
              />
            </button>
          </div>

          <ul className={`sub-menu ${boutiqueOpen ? "active" : ""}`}>
            <li>
              <Link to="/tableaux" onClick={closeMenu}>
                <div className="sidebar-link-wrapper">
                  <PiPaintBrushBroadBold className="sidebar-icon sidebar-submenu-icon" />
                  <p>•</p>
                  <p>Tableaux</p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/bombes" onClick={closeMenu}>
                <div className="sidebar-link-wrapper">
                  <TfiSpray className="sidebar-icon sidebar-submenu-icon" />
                  <p>•</p>
                  <p>Bombes</p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/pour_decorer" onClick={closeMenu}>
                <div className="sidebar-link-wrapper">
                  <IoSparklesOutline className="sidebar-icon sidebar-submenu-icon" />
                  <p>•</p>
                  <p>Pour décorer</p>
                </div>
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/idees_cadeaux" onClick={closeMenu}>
            <div className="sidebar-link-wrapper">
              <GoGift className="sidebar-icon" />
              <p>•</p>
              <p>Idées cadeaux</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/blog" onClick={closeMenu}>
            <div className="sidebar-link-wrapper">
              <FaRegCommentDots className="sidebar-icon" />
              <p>•</p>
              <p>Blog</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={closeMenu}>
            <div className="sidebar-link-wrapper">
              <BiBookOpen className="sidebar-icon" />
              <p>•</p>
              <p>À propos</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={closeMenu}>
            <div className="sidebar-link-wrapper">
              <BiHelpCircle className="sidebar-icon" />
              <p>•</p>
              <p>Contact</p>
            </div>
          </Link>
        </li>
      </ul>
      <div className="btn-command-sidebar-container">
        <Link
          className="btn parallelogram display-element btn-command-sidebar"
          to="/shop"
          onClick={closeMenu}
        >
          <span className="skew-fix">Commander</span>
        </Link>
      </div>
    </aside>
  );
};

export default Sidebar;
