import React from "react";
import { Link } from "react-router-dom";

import "./ArticlesNoPersoCard.css";

// ADD KEY TO THE DIV (+CARD_DATA)

const ArticleNoPersoCard = (data, index) => {
  const ArticleData = data.data;

  const redirectToArticle = (e) => {
    e.preventDefault();
    window.location.href = `/articles/${ArticleData.articleId}`;
  }

  return (
    <div
      className="article-item"
      key={index}
      onClick={(e) => redirectToArticle(e)}
    >
      <div className="article-image">
        <img src={ArticleData.articleImage[0]} alt="1" />
      </div>
      <div className="article-content">
        <div className="content-left">
          <h3>{ArticleData.articleTitle}</h3>
          <p className="cropped-text">{ArticleData.articleDescription}</p>
        </div>
        <div className="content-right">
          <span className="article-price">{ArticleData.price} €</span>
        </div>
      </div>
      <div className="btn-unpersonalized-articles-container">
        <Link
          className="btn-black margin parallelogram-black btn-unpersonalized-articles"
          to={`/articles/${ArticleData.articleId}`}
        >
          <p className="skew-fix">En savoir plus</p>
        </Link>
      </div>
    </div>
  );
};

export default ArticleNoPersoCard;
