import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="left-footer">
        <div className="text-footer">
          <Link to="/">
          <img
            src={require("../../images/typo-white.png")}
            width="170"
            alt=""
            className="logo-typo-navbar"
          />
          </Link>
          <p>© 2023 - {new Date().getFullYear()} Flowarttag</p>
        </div>
      </div>
      <div className="link-footer">
        <Link to="/">Acceuil</Link>
        <Link to="/shop">Boutique</Link>
        <Link to="/idees_cadeaux">Idées cadeaux</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/about">À propos</Link>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="reseaux">
        <div className="links-reseaux-footer">
          <Link to="">
            <BsInstagram className="footer-icon" />
          </Link>
          <Link to="">
            <FaTiktok className="footer-icon" />
          </Link>
        </div>
        <div className="footer-separator"></div>
        <Link to="/cgu">CGU</Link>
        <Link to="/cgv">CGV</Link>
      </div>
    </footer>
  );
}
