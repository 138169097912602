import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useLocalStorage } from "../../../middlewares/localStorage";
import { getTableauxPrice } from "../../../middlewares/getPrices";
import PopUpCart from "../../../components/PopUpCart/PopUpCart";
import { getArticleAvailability } from "../../../api/products";

export default function Tableaux() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Personnaliser mon tableau";
  }, []);
  const [image, setImage] = useState(null);
  const [toSend, setToSend] = useState({
    nbPersonnes: "1-2",
    format: "18x24",
    orientation: "horizontal",
    message: "",
    image: image,
  });
  const [PaintingsAvailablity, setPaintingsAvailablity] = useState([]);
  const [possibleCartAddition, setPossibleCartAddition] = useState(true);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [Articles, setArticles] = useLocalStorage("cartContent", {
    paintings: [],
    sprayPaints: [],
    uncustomizedArticles: [],
  });

  const togglePopUp = () => {
    setIsPopupOpen(false);
  };

  let displayedPrice = 0;
  const updateTableaux = (e) => {
    e.preventDefault();
    const existingArticles = { ...Articles };
    existingArticles.paintings.push(toSend);
    setArticles(existingArticles);
    setToSend({
      nbPersonnes: "1-2",
      format: "18x24",
      orientation: "horizontal",
      message: "",
      image: null,
    });
    setImage(null);
    setIsPopupOpen(true);
    setTimeout(() => {
      togglePopUp();
    }, 30000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getArticleAvailability("");
        if (res.status === 200) {
          let items = res.data.items;
          items = items.filter((item) => item.id !== "bombes");
          items = items.filter((item) => item.quantity > 0);
          setPaintingsAvailablity(items);
          if (items.length === 0) {
            setPossibleCartAddition(false);
          }
        }
      } catch (error) {
        console.error("Error fetching contact demands:", error);
      }
    };

    fetchData();
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.id]: e.target.value });
    displayedPrice = getTableauxPrice(toSend);
    console.log(toSend);
  };

  const handleChangeRadio = (e, section) => {
    if (section === "orientation") {
      setToSend({ ...toSend, orientation: e.target.value });
      displayedPrice = getTableauxPrice(toSend);
    }
  };

  const handleImageUpload = (e, setImage) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      const promise = new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });

      reader.readAsDataURL(file);

      promise.then((result) => {
        setImage(result);
        setToSend({ ...toSend, image: result });
      });
    }
  };

  displayedPrice = getTableauxPrice(toSend);

  return (
    <>
      <Navbar />
      <p className="title-shop">Tableaux personnalisés</p>
      <section className="buy">
        <div className="container-img-buy">
          <img
            className="img-tableau"
            src={require("../../../images/tableau_personnalise_mariage.jpg")}
            alt="Tableau personnalisable et vendu au détail"
          />
        </div>
        <form className="container-text-buy">
          <h3 className="mini-title">Je personnalise mon tableau :</h3>

          <div className="selector">
            <p>Nombre de personne :</p>
            <select
              id="nbPersonnes"
              name="nombres"
              value={toSend.nbPersonnes}
              onChange={handleChange}
              required={true}
            >
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
              <option value="5-6">5-6</option>
              <option value="7-8">7-8</option>
              <option value="9-10">9-10</option>
              <option value="11+">11+</option>
            </select>
          </div>
          <div className="selector">
            <p>Format :</p>
            <select
              id="format"
              name="format"
              value={toSend.format}
              onChange={handleChange}
              required={true}
            >
              {PaintingsAvailablity.map((painting, index) => (
                <option key={index} value={painting.id}>
                  {painting.id}
                </option>
              ))}
            </select>
          </div>
          <div className="orientation">
            <p>Orientation : </p>
            <div className="form-orient">
              <input
                className="radio pointer-cursor"
                type="radio"
                id="orientation_horizontal"
                name="orientation"
                value="horizontal"
                checked={toSend.orientation === "horizontal"}
                onChange={(e) => handleChangeRadio(e, "orientation")}
              />
              <label htmlFor="orientation_horizontal">Horizontal</label>
              <br />
              <input
                className="radio pointer-cursor"
                type="radio"
                id="orientation_vertical"
                name="orientation"
                value="vertical"
                checked={toSend.orientation === "vertical"}
                onChange={(e) => handleChangeRadio(e, "orientation")}
              />
              <label htmlFor="orientation_vertical">Vertical</label>
              <br />
              <input
                className="radio pointer-cursor"
                type="radio"
                id="orientation_indifferent"
                name="orientation"
                value="indifferent"
                checked={toSend.orientation === "indifferent"}
                onChange={(e) => handleChangeRadio(e, "orientation")}
              />
              <label htmlFor="orientation_indifferent">Indifferent</label>
              <br />
            </div>
          </div>
          <div className="other">
            <p>Des envies particulières?</p>
            <textarea
              name="message"
              rows="5"
              cols="50"
              placeholder="Des détails dans le ciel, un soleil..."
              value={toSend.message}
              onChange={handleChange}
              id="message"
            ></textarea>
          </div>

          <div className="exemples">
            <p>Des exemples à me suggérer ?</p>
            <input
              className="input-file"
              type="file" // Change type to "file"
              name="exemple"
              id="exemple"
              accept="image/*" // Add this to allow only image files
              onChange={(e) => handleImageUpload(e, setImage)}
            />
            <label htmlFor="exemple">Envoyer une image</label>

            {image && (
              <div className="image-preview">
                <p>Votre image:</p>
                <img
                  className="preview-image"
                  src={image}
                  alt="Preview"
                  width="100"
                  height="100"
                />
              </div>
            )}
          </div>

          {possibleCartAddition ? (
            <>
              <div className="montant-final">
                <p className="title-montant">Montant final :</p>
                <p className="montant">{displayedPrice} €</p>
              </div>

              <button className="btn-cart" onClick={(e) => updateTableaux(e)}>
                Ajouter au panier
              </button>
            </>
          ) : (
            <>
              <div className="montant-final">
                <p className="title-montant">Montant final :</p>
                <p className="montant">{displayedPrice} €</p>
                <p>
                  <br />
                  Ce produit est actuellement en rupture de stock!
                </p>
              </div>
              <button className="btn-cart" disabled>
                Indisponible
              </button>
            </>
          )}
        </form>
        <PopUpCart isOpen={isPopupOpen} closePopUp={togglePopUp} />
      </section>
      <Footer />
    </>
  );
}
