import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import GiftComponent from "../../components/GiftComponent/GiftComponent";

export default function Gifts() {
  const [isIphone, setIsIphone] = useState(false);
  const [isIpad, setIsIpad] = useState(false);
  const [isMac, setIsMac] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Inspirations cadeaux";
    if (navigator.userAgent.match(/iPhone/i)) {
      setIsIphone(true);
    } else if (navigator.userAgent.match(/iPad/i)) {
      setIsIpad(true);
    } else if (navigator.userAgent.match(/Macintosh/i)) {
      setIsMac(true);
    }
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Des idées cadeau à foison</h1>
      <GiftComponent
        title={"Offrez un cadeau innoubliable pour Noël"}
        description={
          "Noël c’est la fête qui permet de réunir tous vos proches. Optez pour un cadeau qui a du sens, tel qu’un tableau de famille pour cette occasion si particulière."
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_noel_1.jpg")}
        image2={require("../../images/idee_cadeau_noel_2.png")}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={"L'amour est dans l'air..."}
        description={
          "À l’occasion de la saint Valentin, sélectionnez les plus belles photos de vous 2 et illustrez les avec œuvre personnalisée!"
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_st_valentin_1.jpg")}
        image2={require("../../images/idee_cadeau_st_valentin_2.jpg")}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={"Album photo ou tableau personnalisé ?"}
        description={
          "Ne laissez pas vos souvenirs de vacances se perdre dans un album photo. Immortalisez vos plus voyages avec un tableau personnalisé que vous pourrez contempler toute l’année."
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_voyage_1.png")}
        image2={require("../../images/idee_cadeau_voyage_2.png")}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={"Le mariage est un événement unique!"}
        description={
          "Le mariage est un événement que nous capturons avec des photographes, des photomatons, ou encore des smartphones. Que pensez-vous d’illustrer ce moment avec un tableau à accrocher sur votre cheminée ?"
        }
        bgColor={"#ffffff"}
        image1={require("../../images/tableau_personnalise_mariage.jpg")}
        image2={null}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={"Les parents sont à l'honneur!"}
        description={
          "La fête des mères et la fête des pères sont des occasions pour exprimer tout notre amour et notre gratitude envers nos parents. Exprimez vos plus beaux sentiments grâce à nos oeuvres personnalisées."
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_fete_pere_mere_1.png")}
        image2={require("../../images/idee_cadeau_fete_pere_mere_2.jpg")}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={"N'oublions pas nos amis à 4 pattes..."}
        description={
          "Un tableau pour les membres de vos familles qui ont 4 pattes ? Oui je parle bien de vos animaux de compagnie !  Capturez vos moments de complicité avec nos tableaux."
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_animal_1.jpg")}
        image2={require("../../images/idee_cadeau_animal_2.jpg")}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={
          "Revivez des moments passés et laisser ressortir votre nostalgie"
        }
        description={
          "Vos photos d’enfance avec vos frères et sœurs sont scotchées dans les albums de vos parents ? Faites un ou plusieurs tableau personnalisé et accrochez les dans les couloirs de vos foyers."
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_enfance_1.png")}
        image2={require("../../images/idee_cadeau_enfance_2.jpg")}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <GiftComponent
        title={"Et si on se plongeait dans le monde des super-héros ?"}
        description={
          "Plongez dans l'univers captivant de vos héros préférés de fiction en commandant un tableau personnalisé. Une décoration simple et esthétique."
        }
        bgColor={"#ffffff"}
        image1={require("../../images/idee_cadeau_super_heros_1.jpg")}
        image2={null}
        isIphone={isIphone}
        isIpad={isIpad}
        isMac={isMac}
      />
      <Footer />
    </>
  );
}
