import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { HiExternalLink } from "react-icons/hi";

import "./Blog.css";

export default function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Blog";
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Mon blog</h1>
      <div className="blog-wrapper">
        <div className="margin-bottom-blog">
          <p className="txt-centered">
            Afin de rester informé de toutes les actualités concernant mes
            œuvres, je vous invite à visiter mon blog. Vous y trouverez des
            articles dédiés à mon univers créatif, ainsi que des mises à jour
            régulières sur mes nouvelles réalisations. Merci de votre soutien
            continu et de votre intérêt pour mon travail !
          </p>
        </div>

        <div className="blog-btn-layout">
          <a
            type="submit"
            className="blog-btn btn-black margin parallelogram-black"
            href="https://florinegirard75.wixsite.com/website"
            target="_black"
            rel="noopener"
          >
            <div className="blog-link">
              <HiExternalLink />
            </div>

            <span className="skew-fix">Visiter mon blog</span>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}
