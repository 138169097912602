import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
// import { BsCart } from "react-icons/bs";
import "./Navbar.css";
import Sidebar from "../Sidebar/Sidebar";
import CartIcon from "../CartItem/CartItem";
import { useLocalStorage } from "../../middlewares/localStorage";

export default function Navbar() {
  // eslint-disable-next-line no-unused-vars
  const [Articles, setArticles] = useLocalStorage("cartContent", {
    paintings: [],
    sprayPaints: [],
    uncustomizedArticles: [],
  });

  const numberofArticles =
    Articles.paintings.length +
    Articles.sprayPaints.length +
    Articles.uncustomizedArticles.length;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="navbar-logo">
        <Link to="/">
          <img
            src={require("../../images/typo-white.png")}
            width="170"
            alt=""
            className="logo-typo-navbar"
          />
        </Link>
      </div>

      <div className="link">
        <Link to="/">Accueil</Link>
        <div className="dropdown">
          <Link to="/shop">Boutique</Link>
          <div className="dropdown-content">
            <div className="links-dropdown">
              <Link to="/tableaux">Tableaux personnalisés</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/bombes">Bombes personnalisées</Link>
            </div>
            <div>
              <Link to="/pour_decorer">Oeuvres non personnalisées</Link>
            </div>
          </div>
        </div>
        <div className="dropdown">
          <Link to="/idees_cadeaux">Idées cadeaux</Link>
          <div className="dropdown-content">
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Noël</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">St Valentin</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Souvenirs de vacances</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Mariages</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Fêtes des mères/pères</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Amis à quatre pattes</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Moments de nostalgie</Link>
            </div>
            <div className="links-dropdown">
              <Link to="/idees_cadeaux#">Super-héros</Link>
            </div>
          </div>
        </div>
        <Link to="/blog">Blog</Link>
        <Link to="/about">À propos</Link>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="right-navbar">
        {" "}
        <Link to="/cart">
          <CartIcon numberofArticles={String(numberofArticles)} />
        </Link>
        <Link className="btn parallelogram" to="/shop">
          <span className="skew-fix">Commander</span>
        </Link>
      </div>

      <div className="navbar-right-icons">
        <div className="menu-icon">
          <Link to="/cart">
            <CartIcon
              numberofArticles={String(numberofArticles)}
              className="navbar-burger-icon"
            />
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <FaBarsStaggered className="navbar-burger-icon" />
        </div>
      </div>

      {/* Sidebar */}
      <Sidebar isOpen={isMenuOpen} closeMenu={toggleMenu} />
    </header>
  );
}
