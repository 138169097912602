import { Link } from "react-router-dom";
import "./HomeCaroussel.css";

export default function CarousselItem({ title, text, link, backgroundColor, imageContent }) {
  const additionalStyle = {
    backgroundColor: backgroundColor || "white",
  };
  return (
    <div className="container">
      <div className="left-side" style={additionalStyle}>
        <div className="left-content">
          <div className="title">{title}</div>
          <div className="caroussel-text">{text}</div>
          <Link to={"/" + String(link) || "/"} className="btn-black margin parallelogram-black caroussel-button">
            <span className="skew-fix">En savoir plus</span>
          </Link>
        </div>
      </div>
      <img src={imageContent} alt="Test" className="right-side"></img>
    </div>
  );
}
