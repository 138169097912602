import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./CG.css";

export default function Refund() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Politique de remboursement";
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Politique de Remboursement</h1>
      <div className="container-cg">
        <h2 className="cg-section-title">Introduction</h2>
        <p className="cg-section-text">
          Bienvenue sur le site de{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          , nous espérons que vous appréciez votre visite. <br />
          Notre politique de remboursement a été conçue pour vous aider à
          comprendre vos droits et vos obligations lorsque vous achetez des
          produits sur notre site. <br />
          Nous vous invitons à la lire attentivement.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          1. Achats de biens physique par voie dématérialisée
        </h2>
        <p className="cg-section-text">
          Tous les achats effectués sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          sont considérés comme des achats de biens physiques par voie
          dématérialisée. <br />
          <br />
          L'article L.121-20-2 du Code français de la consommation donne à
          l'Acheteur un droit de rétractation d'une durée de 7 jours maximum à
          compter du paiement de la commande. <br />
          <br />
          Nous comprenons cependant que des circonstances exceptionnelles
          peuvent survenir.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">2. Remboursements exceptionnels</h2>
        <p className="cg-section-text">
          Nous considérons les demandes de remboursement dans les cas suivants :
          <br />
          <br />
          Commande : Vous avez a un droit de rétractation de 7 jours si le
          service commandé ne vous a pas pas encore été livré alors que son
          paiement est effectif. Dans ce cas, il vous faudra contacter{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          par mail:{" "}
          <a className="link-legal" href="mailto:flow.arttag.pro@gmail.com">
            flow.arttag.pro@gmail.com
          </a>{" "}
          en fournissant l’ensemble des indications nécessaires (numéro de
          paiement, etc.).{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          procédera au remboursement dans un délai maximum de 30 jours.
          <br />
          <br />
          Erreurs de Facturation : Si vous rencontrez des problèmes liés à la
          facturation, veuillez nous contacter immédiatement à l'adresse:{" "}
          <a className="link-legal" href="mailto:flow.arttag.pro@gmail.com">
            flow.arttag.pro@gmail.com
          </a>{" "}
          avec les détails de la transaction.
          <br />
          <br />
          Problèmes Techniques : Si un achat est effectué en raison d'un
          problème technique ou d'un bug sur le site, veuillez nous fournir les
          détails du problème par mail:{" "}
          <a className="link-legal" href="mailto:flow.arttag.pro@gmail.com">
            flow.arttag.pro@gmail.com
          </a>
          . Nous évaluerons chaque cas individuellement.
          <br />
          <br />
          Achats non autorisés : Si vous constatez un achat non autorisé sur
          votre compte, veuillez nous en informer dès que possible à l'adresse:{" "}
          <a className="link-legal" href="mailto:flow.arttag.pro@gmail.com">
            flow.arttag.pro@gmail.com
          </a>
          . Nous prendrons des mesures immédiates pour enquêter sur le problème.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          3. Processus de Demande de Remboursement
        </h2>
        <p className="cg-section-text">
          Pour demander un remboursement exceptionnel, veuillez envoyer un
          e-mail à notre équipe de support à l'adresse suivante :{" "}
          <a className="link-legal" href="mailto:flow.arttag.pro@gmail.com">
            flow.arttag.pro@gmail.com
          </a>
          . Veuillez inclure les informations suivantes dans votre demande :
          <br />
          <br />
          Nom & Prénom : Votre état civil
          <br />
          <br />
          ID de Transaction : Le numéro de transaction de l'achat que vous
          souhaitez rembourser. (Numéro inscrit sur la facture)
          <br />
          <br />
          Description du Problème : Une description détaillée du problème
          rencontré.
          <br />
          <br />
          Notre équipe de support examinera votre demande et vous contactera
          dans les plus brefs délais pour discuter de votre remboursement.
          <br />
          <br />
          Les produits dont vous n'avez pas respecté les conditions
          d'utilisation ne seront pas remboursés. En outre, vous ne serez pas
          éligible à un remboursement si{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          constate que vous n'avez pas respecté la politique de remboursement.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">4. Droits légaux</h2>
        <p className="cg-section-text">
          Cette politique de remboursement est conçue dans le respect des lois
          françaises relatives aux achats numériques (l’article L.121-20-2 du
          Code français de la consommation). Elle n'affecte pas vos droits
          légaux en tant que consommateur en vertu de ces lois.
          <br />
          <br />
          Nous nous réservons le droit de modifier cette politique de
          remboursement à tout moment sans préavis. Les modifications apportées
          à la politique de remboursement entreront en vigueur dès leur
          publication sur notre site Web.
          <br />
          <br />
          Merci de votre compréhension et de votre soutien continu à{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          . Si vous avez des questions ou des préoccupations concernant notre
          politique de remboursement, n'hésitez pas à nous contacter à{" "}
          <a className="link-legal" href="mailto:flow.arttag.pro@gmail.com">
            flow.arttag.pro@gmail.com
          </a>{" "}
          .
        </p>
      </div>
      <Footer />
    </>
  );
}
