import "./GiftComponent.css";

const GiftComponent = ({
  title,
  description,
  bgColor,
  image1,
  image2,
  isIphone,
  isMac,
  isIpad,
}) => {
  let oneImageIphone = false;
  let oneImageAndroid = false;
  if ((isIphone || isIpad) && image2 === null) {
    oneImageIphone = true;
  }
  if (!isIphone && image2 === null) {
    oneImageAndroid = true;
  }
  return (
    <div
      className={`gift-component-container ${
        oneImageIphone ? "one-image-container" : ""
      } ${oneImageAndroid ? "one-image-container-android" : ""}`}
      style={{ backgroundColor: bgColor }}
    >
      <h2 className="gift-component-title">{title}</h2>
      <p className="gift-component-centered-text">{description}</p>
      <div className={`gift-component-image-row `}>
        <img
          className={`gift-component-row-image ${isMac ? "mac-imagerow" : ""} ${
            isIpad ? "ipad-imagerow" : ""
          }`}
          src={image1}
          alt="component"
        ></img>

        {image2 === null ? null : (
          <img
            className={`gift-component-row-image${
              isMac ? "mac-imagerow" : ""
            } ${isIpad ? "ipad-imagerow" : ""}`}
            src={image2}
            alt="component"
          ></img>
        )}
      </div>
    </div>
  );
};

export default GiftComponent;
