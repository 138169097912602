import { TiWarning } from "react-icons/ti";
import "./AlertBox.css";

const AlertBox = ({ displayedMessage }) => {
  return (
    <div className="alert-box-incomplete-fields-container">
      <div className="alert-box-incomplete-fields">
        <TiWarning className="popup-icon"/>
        <p className="alert-box-text">{displayedMessage}</p>
      </div>
    </div>
  );
};

export default AlertBox;
