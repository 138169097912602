import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import "./Bombes_Tableaux.css";
import { getBombesPrice } from "../../../middlewares/getPrices";
import { useLocalStorage } from "../../../middlewares/localStorage";
import PopUpCart from "../../../components/PopUpCart/PopUpCart";
import { getArticleAvailability } from "../../../api/products";

export default function Bombes() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Personnaliser ma bombe";
  }, []);
  const [isSecondDivActive, setIsSecondDivActive] = useState(false);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [BombesAvailable, setBombesAvailable] = useState(false);
  const [toSend, setToSend] = useState({
    nbPersonnes: "1-2",
    orientation: "horizontal",
    message: "",
    image: image,
    verso: false,
    nbPersonnes2: "1-2",
    orientation2: "horizontal",
    message2: "",
    image2: image2,
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopUp = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getArticleAvailability("bombes");
        if (res.status === 200) {
          if (Number(res.data.item.quantity) > 0) {
            setBombesAvailable(true);
          }
        }
      } catch (error) {
        console.error("Error fetching contact demands:", error);
      }
    };

    fetchData();
  });

  const [Articles, setArticles] = useLocalStorage("cartContent", {
    paintings: [],
    sprayPaints: [],
    uncustomizedArticles: [],
  });

  const updateBombes = (e) => {
    e.preventDefault();
    const existingArticles = { ...Articles };
    existingArticles.sprayPaints.push(toSend);
    setArticles(existingArticles);
    setToSend({
      nbPersonnes: "1-2",
      orientation: "horizontal",
      message: "",
      image: image || null,
      verso: false,
      nbPersonnes2: "1-2",
      orientation2: "horizontal",
      message2: "",
      image2: image2 || null,
    });
    setImage(null);
    setImage2(null);
    setIsPopupOpen(true);
    setTimeout(() => {
      togglePopUp();
    }, 30000);
  };

  let displayedPrice = 0;

  const handleVisibility = () => {
    setIsSecondDivActive(!isSecondDivActive);
    setToSend({ ...toSend, verso: !isSecondDivActive });
    displayedPrice = getBombesPrice(toSend);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.id]: e.target.value });
    console.log(toSend);
    displayedPrice = getBombesPrice(toSend);
    console.log(displayedPrice);
  };

  const handleChangeRadio = (e, section) => {
    if (section === "orientation") {
      setToSend({ ...toSend, orientation: e.target.value });
    } else if (section === "orientation2") {
      setToSend({ ...toSend, orientation2: e.target.value });
    } else if (section === "verso") {
      setToSend({ ...toSend, verso: e.target.value });
    }
    displayedPrice = getBombesPrice(toSend);
  };

  const handleImageUpload = (e, setImage, id) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      const promise = new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });

      reader.readAsDataURL(file);

      promise.then((result) => {
        if (id === "image") {
          setImage(result);
          setToSend({ ...toSend, image: result });
        } else if (id === "image2") {
          setImage2(result);
          setToSend({ ...toSend, image2: result });
        }
      });
    }
  };

  displayedPrice = getBombesPrice(toSend);

  return (
    <>
      <Navbar />
      <p className="title-shop">Bombes personnalisées</p>
      <section className="buy">
        <div className="container-img-buy">
          <img
            className="img-tableau"
            src={require("../../../images/bombes_avant_personnalisees_accueil.png")}
            alt=""
          />
        </div>
        <form className="container-text-buy">
          <h3 className="mini-title">Je personnalise l'avant de ma bombe :</h3>

          <div className="selector">
            <p>Nombre de personne :</p>
            <select
              id="nbPersonnes"
              name="nombres"
              value={toSend.nbPersonnes}
              onChange={handleChange}
              required={true}
            >
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
              <option value="5-6">5-6</option>
              <option value="7-8">7-8</option>
              <option value="9-10">9-10</option>
              <option value="11+">11+</option>
            </select>
          </div>
          <div className="orientation">
            <p>Orientation : </p>
            <div className="form-orient">
              <input
                className="radio pointer-cursor"
                type="radio"
                id="orientation_horizontal"
                name="orientation"
                value="horizontal"
                checked={toSend.orientation === "horizontal"}
                onChange={(e) => handleChangeRadio(e, "orientation")}
              />
              <label htmlFor="orientation_horizontal">Horizontal</label>
              <br />
              <input
                className="radio pointer-cursor"
                type="radio"
                id="orientation_vertical"
                name="orientation"
                value="vertical"
                checked={toSend.orientation === "vertical"}
                onChange={(e) => handleChangeRadio(e, "orientation")}
              />
              <label htmlFor="orientation_vertical">Vertical</label>
              <br />
              <input
                className="radio pointer-cursor"
                type="radio"
                id="orientation_indifferent"
                name="orientation"
                value="indifferent"
                checked={toSend.orientation === "indifferent"}
                onChange={(e) => handleChangeRadio(e, "orientation")}
              />
              <label htmlFor="orientation_indifferent">Indifferent</label>
              <br />
            </div>
          </div>
          <div className="other">
            <p>Des envies particulières?</p>
            <textarea
              name="message"
              rows="5"
              cols="50"
              placeholder="Des détails dans le ciel, un soleil..."
            ></textarea>
          </div>

          <div className="exemples">
            <p>Des exemples à me suggérer ?</p>
            <input
              className="input-file"
              type="file" // Change type to "file"
              name="exemple"
              id="exemple"
              accept="image/*" // Add this to allow only image files
              onChange={(e) => handleImageUpload(e, setImage, "image")}
            />
            <label htmlFor="exemple">Envoyer une image</label>

            {image && (
              <div className="image-preview">
                <p>Votre image:</p>
                <img
                  className="preview-image"
                  src={image}
                  alt="Preview"
                  width="100"
                  height="100"
                />
              </div>
            )}
          </div>

          {toSend.verso === false && BombesAvailable !== false && (
            <>
              <div className="montant-final">
                <p className="title-montant">Montant final :</p>
                <p className="montant">{displayedPrice} €</p>
              </div>
              <button className="btn-cart" onClick={(e) => updateBombes(e)}>
                Ajouter au panier
              </button>
            </>
          )}

          {BombesAvailable === false && (
            <>
              <div className="montant-final">
                <p className="title-montant">Montant final :</p>
                <p className="montant">{displayedPrice} €</p>
                <p>
                  <br />
                  Ce produit est actuellement en rupture de stock!
                </p>
              </div>
              <button className="btn-cart" disabled>
                Indisponible
              </button>
            </>
          )}
        </form>
      </section>
      <div className="radiochecking">
        <input
          type="checkbox"
          id="radioButton"
          name="secondSideChoice"
          checked={toSend.verso}
          onChange={handleVisibility}
          className="radiobutton-bombes"
        />
        <label htmlFor="radioButton" className="radio-label">
          Je souhaite également décorer l'arrière de ma bombe
        </label>
      </div>

      {isSecondDivActive && (
        <section className="buy">
          <div className="container-img-buy">
            <img
              className="img-tableau"
              src={require("../../../images/bombe_personnalisee_verso.png")}
              alt=""
            />
          </div>
          <form className="container-text-buy">
            <h3 className="mini-title">
              Je personnalise l'arrière de ma bombe :
            </h3>
            <div className="selector">
              <p>Nombre de personne :</p>
              <select
                id="nbPersonnes2"
                name="nombres"
                value={toSend.nbPersonnes2}
                onChange={handleChange}
                required={false}
              >
                <option value="1-2">1-2</option>
                <option value="3-4">3-4</option>
                <option value="5-6">5-6</option>
                <option value="7-8">7-8</option>
                <option value="9-10">9-10</option>
                <option value="11+">11+</option>
              </select>
            </div>
            <div className="orientation">
              <p>Orientation : </p>
              <div className="form-orient">
                <input
                  className="radio pointer-cursor"
                  type="radio"
                  id="orientation2_horizontal"
                  name="orientation2"
                  value="horizontal"
                  checked={toSend.orientation2 === "horizontal"}
                  onChange={(e) => handleChangeRadio(e, "orientation2")}
                />
                <label htmlFor="orientation2_horizontal">Horizontal</label>
                <br />
                <input
                  className="radio pointer-cursor"
                  type="radio"
                  id="orientation2_vertical"
                  name="orientation2"
                  value="vertical"
                  checked={toSend.orientation2 === "vertical"}
                  onChange={(e) => handleChangeRadio(e, "orientation2")}
                />
                <label htmlFor="orientation2_vertical">Vertical</label>
                <br />
                <input
                  className="radio pointer-cursor"
                  type="radio"
                  id="orientation2_indifferent"
                  name="orientation2"
                  value="indifferent"
                  checked={toSend.orientation2 === "indifferent"}
                  onChange={(e) => handleChangeRadio(e, "orientation2")}
                />
                <label htmlFor="orientation2_indifferent">Indifferent</label>
                <br />
              </div>
            </div>
            <div className="other">
              <p>Des envies particulières?</p>
              <textarea
                name="message"
                rows="5"
                cols="50"
                placeholder="Des détails dans le ciel, un soleil..."
              ></textarea>
            </div>
            <div className="exemples">
              <p>Des exemples à me suggérer ?</p>
              <input
                className="input-file"
                type="file" // Change type to "file"
                name="exemple"
                id="exemple"
                accept="image/*" // Add this to allow only image files
                onChange={(e) => handleImageUpload(e, setImage, "image2")}
              />
              <label htmlFor="exemple">Envoyer une image</label>

              {image2 && (
                <div className="image-preview">
                  <p>Votre image:</p>
                  <img
                    className="preview-image"
                    src={image2}
                    alt="Preview"
                    width="100"
                    height="100"
                  />
                </div>
              )}
            </div>
            {BombesAvailable !== false && (
              <>
                <div className="montant-final">
                  <p className="title-montant">Montant final :</p>
                  <p className="montant">{displayedPrice} €</p>
                </div>
                <button className="btn-cart" onClick={(e) => updateBombes(e)}>
                  Ajouter au panier
                </button>
              </>
            )}
            {BombesAvailable === false && (
              <>
                <div className="montant-final">
                  <p className="title-montant">Montant final :</p>
                  <p className="montant">{displayedPrice} €</p>
                  <p>
                    <br />
                    Ce produit est actuellement en rupture de stock!
                  </p>
                </div>
                <button className="btn-cart" disabled>
                  Indisponible
                </button>
              </>
            )}
          </form>
          <PopUpCart isOpen={isPopupOpen} closePopUp={togglePopUp} />
        </section>
      )}

      <Footer />
    </>
  );
}
