import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Contact.css";
import { putContactDemand } from "../../api/contact";
import AlertBox from "../../components/AlertBox/AlertBox";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Me contacter";
  }, []);

  const [contactObject, setContactObject] = useState({
    email: "",
    name: "",
    content: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleChange = (e) => {
    setContactObject({ ...contactObject, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      contactObject.content.length === 0 ||
      contactObject.email.length === 0 ||
      contactObject.name.length === 0
    ) {
      setIsPopupOpen(true);
      setTimeout(() => {
        setIsPopupOpen(false);
      }, 7000);
    } else {
      putContactDemand(contactObject).then((r) => {
        if (r.status === 200) {
          setContactObject({
            email: "",
            name: "",
            content: "",
          });
        } else {
          console.log("error");
        }
      });
    }
  };
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Me contacter</h1>
      <div className="div-txt-centered">
        <p className="txt-centered">
          N'hésitez pas à me contacter pour toute question et demande
          d'information. Je suis également disponible sur les réseaux sociaux.
          Votre satisfaction est ma priorité. J'ai hâte d'avoir de vos nouvelles
          et de répondre à vos demandes !
        </p>
        <form className="container-text-contact" onSubmit={onSubmit}>
          <div className="top-inputs">
            <div className="text-input-contact-top">
              <label htmlFor="name">Votre nom :</label>
              <input
                type="text"
                id="name"
                name="name"
                // required={true}
                className="text-field-contact-top"
                placeholder="Pierre Dupont"
                onChange={handleChange}
              />
            </div>
            <div className="text-input-contact-top bottom-text-input-contact text-input-right">
              <div className="text-input-right">
                <label htmlFor="email">Votre e-mail :</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  // required={true}
                  className="text-field-contact-top"
                  placeholder="pierredupont@example.com"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="other">
            <p className="text-left-align">Votre message :</p>
            <textarea
              name="content"
              id="content"
              rows="5"
              cols="75"
              placeholder="Je vous contacte pour savoir si..."
              className="message-placeholder"
              // required={true}
              onChange={handleChange}
            ></textarea>
          </div>
          <button
            type="submit"
            className="submit-btn btn-black margin parallelogram-black"
          >
            <span className="skew-fix">Envoyer</span>
          </button>
        </form>

        {isPopupOpen && (
          <AlertBox
            displayedMessage={"Vous devez remplir tous les champs requis"}
          />
        )}
      </div>

      <Footer />
    </>
  );
}
