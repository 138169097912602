import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./UnpersonalizedArticleDescription.css";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "../../middlewares/localStorage";
import { BsCart } from "react-icons/bs";
import { MdOutlineArrowBack } from "react-icons/md";
import PopUpCart from "../../components/PopUpCart/PopUpCart";
import { getUncustomizedArticle } from "../../api/products";

export default function UnpersonalizedArticleDescription() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { articleId } = useParams();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);

  const togglePopUp = () => {
    setIsPopupOpen(false);
  };

  const [Articles, setArticles] = useLocalStorage("cartContent", {
    paintings: [],
    sprayPaints: [],
    uncustomizedArticles: [],
  });

  const [article, setArticle] = useState({
    articleTitle: "",
    articleDescription: "",
    price: 0,
    articleImage: [],
  });

  const [selectedImage, setSelectedImage] = useState(article.articleImage[0]);

  const addToCart = (e) => {
    e.preventDefault();
    const existingArticles = { ...Articles };
    existingArticles.uncustomizedArticles.push(String(articleId));
    setArticles(existingArticles);
    setIsPopupOpen(true);
    setTimeout(() => {
      togglePopUp();
    }, 30000);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const response = await getUncustomizedArticle(articleId);
        const data = response.data.articles;
        setArticle(data);
        if (data.quantity === 0) {
          setIsAvailable(false);
        }
        document.title = `Flowarttag - ${data.articleTitle}`;
        setSelectedImage(data.articleImage[0]);
      } catch (err) {
        console.error(err);
      }
    };

    fetchArticleData();
  }, [articleId]);

  return (
    <>
      <Navbar />
      <div className="article-container">
        <div className="article-image-desc">
          <img src={selectedImage} alt="Article" className="main-image" />
          <div className="alternative-images">
            {article.articleImage.map((image, index) => (
              <img
                key={index}
                src={image}
                alt="Alternative"
                onClick={() => handleImageClick(image)}
              />
            ))}
          </div>
        </div>
        <div className="article-description-desc">
          <h1>{article.title}</h1>
          <p>{article.articleDescription}</p>

          <p className="unpersonnalized-article-price">
            Prix: {article.price}€
          </p>
          {!isAvailable && (
            <>
              <div className="blog-btn-layout cursor-na">
                <button
                  type="submit"
                  className="blog-btn btn-black margin parallelogram-black disabled cursor-na"
                  onClick={(e) => addToCart(e)}
                  target="_black"
                  rel="noopener"
                  disabled={true}
                >
                  <div className="blog-link icon-straight cursor-na">
                    <BsCart />
                  </div>

                  <span className="skew-fix cursor-na">Ajouter au panier</span>
                </button>
              </div>
              <p className="not-available">
                Cet article n'est plus disponible à la vente.
              </p>
            </>
          )}
          {isAvailable && (
            <div className="blog-btn-layout">
              <button
                type="submit"
                className="blog-btn btn-black margin parallelogram-black"
                onClick={(e) => addToCart(e)}
                target="_black"
                rel="noopener"
              >
                <div className="blog-link icon-straight">
                  <BsCart />
                </div>

                <span className="skew-fix">Ajouter au panier</span>
              </button>
            </div>
          )}

          <div className="blog-btn-layout">
            <Link
              to="/shop"
              className="blog-btn btn-black margin parallelogram-black"
            >
              <div className="blog-link icon-straight">
                <MdOutlineArrowBack />
              </div>
              <span className="skew-fix">Retour à la boutique</span>
            </Link>
          </div>
        </div>
        <PopUpCart isOpen={isPopupOpen} closePopUp={togglePopUp} />
      </div>
      <Footer />
    </>
  );
}
