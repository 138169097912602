import React, {useEffect} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./About.css";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - À propos";
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">À propos de moi</h1>
      <div className="about-container">
        <div className="left">
          <div className="text">
            Je m’appelle Florine Girard et je suis la créatrice derrière
            Flowarttag. Depuis mon plus jeune âge, je suis captivée par le monde
            de l'art et cette marque est pour moi l’occasion de partager cette
            passion avec vous.
            <br /> Aujourd'hui, à 18 ans, je me suis lancé dans une nouvelle
            aventure en septembre 2023. J'ai entamé une année de prépa en arts
            appliqués pour poursuivre mes études en design graphique et digital.
            <br />
            <br />
            <br />
            Mon objectif est de continuer à évoluer en tant qu'artiste et de
            propulser Flowarttag vers de nouveaux horizons. Au-delà des tableaux
            personnalisés, j'aspire à étendre ma créativité et à lancer une
            gamme de vêtements inspirés de mes créations uniques.
            <br />
            Merci de faire partie de l'histoire de Flowarttag, et j'espère que
            vous serez nombreux à me rejoindre dans cette nouvelle aventure.
          </div>
        </div>
        <div className="right">
          <img
            src={require("../../images/florine_about.jpg")}
            alt="1"
            className="image"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
