import React, { useEffect } from "react";
import { useRouteError, Link } from "react-router-dom";
import "./ErrorPage.css";
import Navbar from "../components/Navbar/Navbar";

export default function ErrorPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "404 - Not Found";
  }, []);
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <Navbar />
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Oups! Page introuvable</h3>
            <h1>
              <span>4</span>
              <span>0</span>
              <span>4</span>
            </h1>
          </div>
          <h2>Désolé, mais la page que vous cherchez n'a pas été trouvée</h2>
          <Link to="/" className="btn-black margin parallelogram-black">
            <span className="skew-fix">Retour vers l'accueil</span>
          </Link>
        </div>
      </div>
    </>
  );
}
