import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import "./CG.css";

export default function CGV() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Conditions Générales de Vente";
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Conditions générales de vente</h1>
      <div className="container-cg">
        <h2 className="cg-section-title">Introduction</h2>
        <p className="cg-section-text">
          Les présentes conditions de vente sont conclues d'une part par{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          et d'autre part, par toute personne physique ou morale souhaitant
          procéder à un achat via le site Internet{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          , dénommée ci-après « l'Acheteur».
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">1. Objet du contrat</h2>
        <p className="cg-section-text">
          Les présentes conditions générales de vente ont pour objet de fixer
          les dispositions contractuelles entre{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          , l'Acheteur et les conditions applicables à tout achat effectué sur
          le site. L’acquisition d'un bien ou d'un service à travers le présent
          site implique une acceptation sans réserve par l'Acheteur des
          présentes conditions de vente.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          2. Caractéristiques des produits, biens et services proposés
        </h2>
        <p className="cg-section-text">
          Toutes les caractéristiques des produits et services proposés par{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          sont présentés sur notre boutique virtuelle sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          .
          <br />
          Chaque produit est accompagné d’un descriptif, ainsi que de son prix.
          <br />
          <br />
          Tous les articles vendus sur la boutique de{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          sont valides, sauf contre indication sur le descriptif de l'article.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">3. Tarifs</h2>
        <p className="cg-section-text">
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          se réserve le droit de modifier ses prix à tout moment, étant
          toutefois entendu que le prix figurant au catalogue le jour de la
          commande sera le seul applicable à l’acheteur.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">4. Commande</h2>
        <p className="cg-section-text">
          L'Acheteur passe commande sur le site Internet de{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          . Pour acheter un ou plusieurs articles, il doit obligatoirement
          suivre le processus de commande suivant : <br />
          <br />
          • Inscription sur le site <br />• Choix de l’article <br />•
          Validation de l'achat et paiement <br />• Réception de l’article
          <br />
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          se réserve le droit d’annuler ou de refuser toute commande qui
          émanerait d’un Acheteur avec lequel il existerait un litige relatif au
          paiement d’une commande précédente. Toute commande vaut acceptation
          des prix et descriptions des articles disponibles à la vente.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">5. Modalités de paiement</h2>
        <p className="cg-section-text">
          Le prix est exigible à la commande.
          <br />
          Plusieurs modes de paiements sont possibles.
          <br />
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          n’est pas responsable dès lors que la non validation de la commande
          résulte de cas de force majeur ou d’un problème chez les prestataires.
          <br />
          La sécurité de la transaction financière est la responsabilité de
          notre prestataire Stripe.
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          décline toute responsabilité en cas de dysfonctionnement entraînant la
          non validation de la commande résultant de cas de force majeur ou d’un
          problème chez les prestataires.
          <br />
          Pour tout achat avec un prestataire fonctionnant par un système de
          micropaiement, le prix est plus élevé qu'avec Stripe pour un même
          produit et ce en raison du taux de commission prélevé par cette
          société.
          <br />
          L’ensemble des données fournies et la confirmation enregistrée
          vaudront preuve de la transaction.
          <br />
          La confirmation vaudra signature et acceptation des opérations
          effectuées.
          <br />
          <br />
          Tout achat entraîne l'acceptation des conditions générales de vente de
          ces sociétés :<br />
          <br />
          <a className="link-legal" href="https://stripe.com">
            Stripe
          </a>
          <br />
          Stripe Payments Europe, Ltd. (SPEL)
          <br />
          5e étage
          <br />
          10 Boulevard Haussmann
          <br />
          75009 Paris
          <br />
          +33 805 11 19 67
          <br />
          <a className="link-legal" href="mailto:heretohelp@stripe.com">
            heretohelp@stripe.com
          </a>
          <br />
          <br />
          <a className="link-legal" href="https://laposte.fr">
            La Poste - Colissimo
          </a>
          <br />
          9 rue du Colonel Pierre Avia
          <br />
          75015 Paris
          <br />
          <a className="link-legal" href="https://aide.laposte.fr/email">
            Envoyer un mail
          </a>
          <br />
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">6. Capacité juridique, âge</h2>
        <p className="cg-section-text">
          Pour commander sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          , vous attestez de manière ferme et sous votre propre et unique
          responsabilité que :
          <br />
          <br />
          Vous avez pleine capacité de jouissance et d’exercice pour contracter
          avec nous.
          <br />
          Vous déclarez être âgé d’au moins 18 ans et avoir la capacité
          juridique de conclure le présent contrat. Il ne peut pas nous être
          exigé de vérifier l’âge des acheteurs du site.
          <br />
          Si l’acheteur est une personne physique mineure, il se doit d'obtenir
          le consentement de ses parents/tuteurs avant de passer commande.
          L'autorité parentale reconnaît quant à elle avoir accepté les
          conditions générales et se porte garante de l'Acheteur mineur. Toute
          utilisation du site Paladium et de ses services par l'Acheteur mineur
          est réalisée sous l'entière responsabilité des titulaires de
          l'autorité parentale.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          7. Expédition et délais de livraison
        </h2>
        <p className="cg-section-text">
          Dès qu’une commande est validée (soit après son paiement effectif),{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          ainsi que Colissimo livreront l'Acheteur concerné.
          <br />
          Les délais de livraison dépendent du service de paiement choisi.
          <br />
          Toute commande passée sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          est livrée immédiatement à compter du moment où le paiement a été
          effectué.
          <br />
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          s’engage à livrer les commandes passées par l'Acheteur dans les délais
          prévus. Si lesdits articles n’ont pas été livrés dans un délai de
          soixante (60) jours à compter de la date de livraison prévue lors de
          la commande, et si ce dépassement n’est pas lié à un cas de force
          majeure indiqué au client par e-mail, l'Acheteur pourra procéder à la
          résolution de la vente, en contactant{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          .
          <br />
          Les sommes réglées par l'Acheteur lui seront alors intégralement
          remboursées.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">8. Responsabilité</h2>
        <p className="cg-section-text">
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          , dans le processus de vente en ligne, n’est tenu que par une
          obligation de moyens : sa responsabilité ne pourra être engagée pour
          un dommage résultant de perturbations du réseau Internet tels que
          perte de données, intrusion, virus, rupture du service, autres
          problèmes involontaires ou d’un problème chez les prestataires (voir
          5).
          <br />
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          , dans le processus de livraison n'est tenu que par une obligation
          d'expédition du colis. Sa responsabilité ne pourra être engagée pour
          un dommage physique résultant du transport d'un de ses colis par un
          prestataires ou la non distribution de celui-ci à la suite d'un
          problème chez les prestataires (voir 5).
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">9. Rétractation et remboursement</h2>
        <p className="cg-section-text">
          Pour toutes questions sur les modalités de rétractation et de
          remboursement merci de consulter notre page de{" "}
          <Link to={"/refund"} className="link-legal">
            politique de remboursement.
          </Link>
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">10. Données à caractère personnel</h2>
        <p className="cg-section-text">
          Conformément aux dispositions des articles 38 et suivants de la loi
          78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
          libertés, tout Acheteur dispose d’un droit d’accès, de rectification
          et d’opposition aux données personnelles le concernant, en effectuant
          sa demande écrite et signée, accompagnée d’une copie du titre
          d’identité avec signature du titulaire de la pièce, en précisant
          l’adresse à laquelle la réponse doit être envoyée. <br />
          Le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          n'est pas enregistré à la CNIL.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">11. Droits</h2>
        <p className="cg-section-text">
          Le présent contrat est soumis aux lois françaises.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">12. Lexique</h2>
        <p className="cg-section-text">
          Site: Le Site désigne ici le site Internet{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          <br />
          Acheteur: l'Acheteur désigne toute personne physique ou morale qui
          utilise le Site ou l'un des services proposés par{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
}
