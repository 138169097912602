import React from "react";
import { BsCart } from "react-icons/bs";
import {
  TbCircle0Filled,
  TbCircle1Filled,
  TbCircle2Filled,
  TbCircle3Filled,
  TbCircle4Filled,
  TbCircle5Filled,
  TbCircle6Filled,
  TbCircle7Filled,
  TbCircle8Filled,
  TbCircle9Filled,
} from "react-icons/tb";
import { FaCirclePlus } from "react-icons/fa6";

import "./CartItem.css";

function CartIcon({ numberofArticles }) {
  return (
    <div className="cart-icon">
      {numberofArticles === "0" && <TbCircle0Filled className="badge" />}
      {numberofArticles === "1" && <TbCircle1Filled className="badge" />}
      {numberofArticles === "2" && <TbCircle2Filled className="badge" />}
      {numberofArticles === "3" && <TbCircle3Filled className="badge" />}
      {numberofArticles === "4" && <TbCircle4Filled className="badge" />}
      {numberofArticles === "5" && <TbCircle5Filled className="badge" />}
      {numberofArticles === "6" && <TbCircle6Filled className="badge" />}
      {numberofArticles === "7" && <TbCircle7Filled className="badge" />}
      {numberofArticles === "8" && <TbCircle8Filled className="badge" />}
      {numberofArticles === "9" && <TbCircle9Filled className="badge" />}
      {parseInt(numberofArticles) > 9 && <FaCirclePlus className="badge" />}
      <BsCart className="cart-icon-navbar" />
    </div>
  );
}

export default CartIcon;
