import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import ArticleNoPersoCard from "../../../components/ArticlesNoPerso/ArticlesNoPersoCard";
import ArticleNoPersoCardSkeleton from "../../../components/ArticlesNoPerso/ArticleNoPersoCardSkeleton";
import "./UnpersonnalizedArticles.css";
import { getUncustomizedArticles } from "../../../api/products";

export default function UnpersonnalizedArticles() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Articles";
  }, []);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      getUncustomizedArticles()
        .then((ArticlesData) => {
          setData(ArticlesData.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError(error.message);
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <p className="title-shop">Mes oeuvres non personnalisées</p>
      {loading ? (
        <div className="articles-container">
          <ArticleNoPersoCardSkeleton />
          <ArticleNoPersoCardSkeleton />
          <ArticleNoPersoCardSkeleton />
        </div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <div className="articles-container">
          {data.articles.map((articleData, index) => (
            <ArticleNoPersoCard index={index} data={articleData} />
          ))}
        </div>
      )}
      <Footer />
    </>
  );
}
