import React from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { getTableauxPrice, getBombesPrice } from "../../middlewares/getPrices";
import "./CartArticleCheckout.css";
import t1 from "../../images/tableau_personnalise_mariage.jpg";
import b1 from "../../images/bombes_avant_personnalisees_accueil.png";

function CartArticleCheckout({ articleData, index, length, removeArticle }) {
  let productTitle = "";
  let productDescription = "";
  let isLast = false;
  let price = 0;
  let imageData = null;
  if (index === length - 1) {
    isLast = true;
  }
  switch (articleData.type) {
    case "tableaux":
      productTitle = `Tableau`;
      productDescription = `${articleData.nbPersonnes} • ${articleData.format} • ${articleData.orientation} • Pas de verso`;
      price = getTableauxPrice(articleData);
      imageData = t1;
      break;
    case "bombes":
      if (articleData.verso) {
        productTitle = `Bombe de peinture`;
        productDescription = `${articleData.nbPersonnes} • ${articleData.format} • ${articleData.orientation} • Verso • ${articleData.nbPersonnes2} • ${articleData.format2} • ${articleData.orientation2}`;
        price = getBombesPrice(articleData);
        imageData = b1;
        break;
      } else {
        productTitle = `Bombe de peinture`;
        productDescription = `${articleData.nbPersonnes} • ${articleData.orientation} • Pas de verso`;
        price = getBombesPrice(articleData);
        imageData = t1;
        break;
      }
    case "uncustomized":
      productTitle = articleData.articleTitle;
      productDescription = articleData.articleDescription;
      price = articleData.price;
      imageData = articleData.articleImage[0];
      break;
    default:
      console.log("error");
      break;
  }

  const handleRemoveArticle = () => {
    console.log(
      "clicked",
      articleData.type,
      articleData.format,
      articleData.articleId
    );
    removeArticle(articleData.type, articleData.format, articleData.articleId);
  };

  return (
    <div
      className={`cart-article-container ${isLast ? "" : "cart-border-bottom"}`}
    >
      <img
        src={`${imageData}`}
        className="round-img-cart"
        alt="tableau personnalisé"
        width={100}
      />
      <div className="cart-product-info">
        <h2>{productTitle}</h2>
        <p>{productDescription}</p>
      </div>
      <FaRegTrashCan className="delete-item" onClick={handleRemoveArticle} />
      <div className="cart-price">{price} €</div>
    </div>
  );
}

export default CartArticleCheckout;
