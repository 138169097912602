const getBombesPrice = (toSend) => {
  switch (
    `${toSend.nbPersonnes}-${toSend.nbPersonnes2}-${String(toSend.verso)}`
  ) {
    case "1-2-1-2-false":
      return 35;
    case "1-2-3-4-false":
      return 35;
    case "1-2-5-6-false":
      return 35;
    case "1-2-7-8-false":
      return 35;
    case "1-2-9-10-false":
      return 35;
    case "1-2-11+-false":
      return 35;
    case "3-4-1-2-false":
      return 40;
    case "3-4-3-4-false":
      return 40;
    case "3-4-5-6-false":
      return 40;
    case "3-4-7-8-false":
      return 40;
    case "3-4-9-10-false":
      return 40;
    case "3-4-11+-false":
      return 40;
    case "5-6-1-2-false":
      return 45;
    case "5-6-3-4-false":
      return 45;
    case "5-6-5-6-false":
      return 45;
    case "5-6-7-8-false":
      return 45;
    case "5-6-9-10-false":
      return 45;
    case "5-6-11+-false":
      return 45;
    case "7-8-1-2-false":
      return 50;
    case "7-8-3-4-false":
      return 50;
    case "7-8-5-6-false":
      return 50;
    case "7-8-7-8-false":
      return 50;
    case "7-8-9-10-false":
      return 50;
    case "7-8-11+-false":
      return 50;
    case "9-10-1-2-false":
      return 55;
    case "9-10-3-4-false":
      return 55;
    case "9-10-5-6-false":
      return 55;
    case "9-10-7-8-false":
      return 55;
    case "9-10-9-10-false":
      return 55;
    case "9-10-11+-false":
      return 55;
    case "11+-1-2-false":
      return 60;
    case "11+-3-4-false":
      return 60;
    case "11+-5-6-false":
      return 60;
    case "11+-7-8-false":
      return 60;
    case "11+-9-10-false":
      return 60;
    case "11+-11+-false":
      return 60;
    case "1-2-1-2-true":
      return 45;
    case "1-2-3-4-true":
      return 50;
    case "1-2-5-6-true":
      return 55;
    case "1-2-7-8-true":
      return 60;
    case "1-2-9-10-true":
      return 65;
    case "1-2-11+-true":
      return 70;
    case "3-4-1-2-true":
      return 50;
    case "3-4-3-4-true":
      return 55;
    case "3-4-5-6-true":
      return 60;
    case "3-4-7-8-true":
      return 65;
    case "3-4-9-10-true":
      return 70;
    case "3-4-11+-true":
      return 75;
    case "5-6-1-2-true":
      return 55;
    case "5-6-3-4-true":
      return 60;
    case "5-6-5-6-true":
      return 65;
    case "5-6-7-8-true":
      return 70;
    case "5-6-9-10-true":
      return 75;
    case "5-6-11+-true":
      return 80;
    case "7-8-1-2-true":
      return 60;
    case "7-8-3-4-true":
      return 65;
    case "7-8-5-6-true":
      return 70;
    case "7-8-7-8-true":
      return 75;
    case "7-8-9-10-true":
      return 80;
    case "7-8-11+-true":
      return 85;
    case "9-10-1-2-true":
      return 65;
    case "9-10-3-4-true":
      return 70;
    case "9-10-5-6-true":
      return 75;
    case "9-10-7-8-true":
      return 80;
    case "9-10-9-10-true":
      return 85;
    case "9-10-11+-true":
      return 90;
    case "11+-1-2-true":
      return 70;
    case "11+-3-4-true":
      return 75;
    case "11+-5-6-true":
      return 80;
    case "11+-7-8-true":
      return 85;
    case "11+-9-10-true":
      return 90;
    case "11+-11+-true":
      return 95;
    default:
      return 35;
  }
};

const getTableauxPrice = (toSend) => {
  switch (`${toSend.nbPersonnes}-${toSend.format}`) {
    case "1-2-18x24":
      return 35;
    case "3-4-18x24":
      return 40;
    case "5-6-18x24":
      return 45;
    case "7-8-18x24":
      return 50;
    case "9-10-18x24":
      return 55;
    case "11+-18x24":
      return 60;
    case "1-2-24x30":
      return 35;
    case "3-4-24x30":
      return 40;
    case "5-6-24x30":
      return 45;
    case "7-8-24x30":
      return 50;
    case "9-10-24x30":
      return 55;
    case "11+-24x30":
      return 60;
    case "1-2-30x40":
      return 40;
    case "3-4-30x40":
      return 45;
    case "5-6-30x40":
      return 50;
    case "7-8-30x40":
      return 55;
    case "9-10-30x40":
      return 60;
    case "11+-30x40":
      return 65;
    case "1-2-20x40":
      return 40;
    case "3-4-20x40":
      return 45;
    case "5-6-20x40":
      return 50;
    case "7-8-20x40":
      return 55;
    case "9-10-20x40":
      return 60;
    case "11+-20x40":
      return 65;
    case "1-2-50x50":
      return 45;
    case "3-4-50x50":
      return 50;
    case "5-6-50x50":
      return 55;
    case "7-8-50x50":
      return 60;
    case "9-10-50x50":
      return 65;
    case "11+-50x50":
      return 70;
    case "1-2-50x60":
      return 45;
    case "3-4-50x60":
      return 50;
    case "5-6-50x60":
      return 55;
    case "7-8-50x60":
      return 60;
    case "9-10-50x60":
      return 65;
    case "11+-50x60":
      return 70;
    default:
      return 35;
  }
};

module.exports = {
  getBombesPrice,
  getTableauxPrice,
};
