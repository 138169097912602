import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./CG.css";

export default function CGU() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Flowarttag - Conditions Générales d'Utilisation";
  }, []);
  return (
    <>
      <Navbar />
      <h1 className="title-shop">Conditions générales d'utilisation</h1>
      <div className="container-cg">
        <h2 className="cg-section-title">1. Présentation du Site</h2>
        <p className="cg-section-text">
          En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
          confiance dans l'économie numérique, il est précisé aux utilisateurs
          du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          l'identité des différents intervenants dans le cadre de sa réalisation
          et de son suivi :
          <br />
          <br />
          Hébergeur : OVH SAS - Siège social : 2 rue Kellermann - 59100 Roubaix
          - France
          <br />
          Pour tout contact et/ou demande d'informations:
          contact@Flowarttag-pvp.fr
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          2. Conditions Générales d’Utilisation du Site et des Services Proposés
        </h2>
        <p className="cg-section-text">
          L’utilisation du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          implique l’acceptation pleine et entière des conditions générales
          d’utilisation ci-après décrites. Ces conditions d’utilisation sont
          susceptibles d’être modifiées ou complétées à tout moment, les
          utilisateurs du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          sont donc invités à les consulter de manière régulière.
          <br />
          <br />
          Ce site est normalement accessible à tout moment aux utilisateurs. Une
          interruption pour raison de maintenance technique peut être toutefois
          décidée par{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          , qui s’efforcera alors de communiquer préalablement aux utilisateurs
          les dates et heures de l’intervention.
          <br />
          <br />
          Le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          est mis à jour régulièrement par{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          . De la même façon, les mentions légales peuvent être modifiées à tout
          moment : elles s’imposent néanmoins à l’utilisateur qui est invité à
          s’y référer le plus souvent possible afin d’en prendre connaissance
          ICI .
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          3. Conditions Générales d’Utilisation du Site et des Services Proposés
        </h2>
        <p className="cg-section-text">
          Le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          a pour objet de fournir une information concernant l’ensemble des
          activités de la société.
          <br />
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          s’efforce de fournir sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          des informations aussi précises que possible. Toutefois, il ne pourra
          être tenue responsable des omissions, des inexactitudes et des
          carences dans la mise à jour, qu’elles soient de son fait ou du fait
          des tiers partenaires qui lui fournissent ces informations.
          <br />
          <br />
          Tous les informations indiquées sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          sont données à titre indicatif et sont susceptibles d’évoluer. Par
          ailleurs, les renseignements figurant sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications
          ayant été apportées depuis leur mise en ligne.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          4. Limitations contractuelles sur les données techniques
        </h2>
        <p className="cg-section-text">
          Le site utilise la technologie JavaScript.
          <br />
          <br />
          Le site Internet ne pourra être tenu responsable de dommages matériels
          liés à l’utilisation du site. De plus, l’utilisateur du site s’engage
          à accéder au site en utilisant un matériel récent, ne contenant pas de
          virus et avec un navigateur de dernière génération mis-à-jour.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          5. Propriété intellectuelle et contrefaçons
        </h2>
        <p className="cg-section-text">
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          est propriétaire des droits de propriété intellectuelle ou détient les
          droits d’usage sur tous les éléments accessibles sur le site,
          notamment les textes, images, graphismes, logo, icônes, sons et
          logiciels.
          <br />
          <br />
          La consultation n’entraîne aucun transfert de droit de propriété
          intellectuelle en faveur de l’utilisateur. La reproduction, la
          rediffusion ou l’extraction automatique par tout moyen d’informations
          figurant sur le site est interdite.{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          se réserve le droit d’entamer toute action visant à faire cesser le
          préjudice.
          <br />
          <br />
          Toute reproduction, représentation, modification, publication,
          adaptation de tout ou partie des éléments du site, quel que soit le
          moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
          préalable de{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          .
          <br />
          <br />
          Toute exploitation non autorisée du site ou d'un quelconque des
          éléments qu’il contient sera considérée comme constitutive d’une
          contrefaçon et poursuivie conformément aux dispositions des articles
          L.335-2 et suivants du Code de Propriété Intellectuelle.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">6. Limitations de responsabilité</h2>
        <p className="cg-section-text">
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          ne pourra être tenue responsable des dommages directs et indirects
          causés au matériel de l’utilisateur, lors de l’accès au site
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          , et résultant soit de l’utilisation d’un matériel ne répondant pas
          aux spécifications indiquées au point 4, soit de l’apparition d’un bug
          ou d’une incompatibilité.
          <br />
          <br />
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          ne pourra également être tenue responsable des dommages indirects
          (tels par exemple qu’une perte de marché ou perte d’une chance)
          consécutifs à l’utilisation du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          .
          <br />
          <br />
          Des espaces interactifs (possibilité de poser des questions dans
          l’espace contact) sont à la disposition des utilisateurs.{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          se réserve le droit de supprimer, sans mise en demeure préalable, tout
          contenu déposé dans cet espace qui contreviendrait à la législation
          applicable en France, en particulier aux dispositions relatives à la
          protection des données. Le cas échéant,{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          se réserve également la possibilité de mettre en cause la
          responsabilité civile et/ou pénale de l’utilisateur, notamment en cas
          de message à caractère raciste, injurieux, diffamant, ou
          pornographique, quel que soit le support utilisé (texte,
          photographie…).
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          7. Gestion des données personnelles
        </h2>
        <p className="cg-section-text">
          En France, les données personnelles sont notamment protégées par la
          loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
          l'article L. 226-13 du Code pénal et la Directive Européenne du 24
          octobre 1995.
          <br />
          <br />A l'occasion de l'utilisation du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          , peuvent êtres recueillis : l'URL des liens par l'intermédiaire
          desquels l'utilisateur a accédé au site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          , le fournisseur d'accès de l'utilisateur, l'adresse de Protocole
          Internet (IP) de l'utilisateur.
          <br />
          <br />
          En tout état de cause,{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          ne collecte des informations personnelles relatives à l'utilisateur
          que pour le besoin de certains services proposés par le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          . L'utilisateur fournit ces informations en toute connaissance de
          cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est
          alors précisé à l'utilisateur du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          l’obligation ou non de fournir ces informations.
          <br />
          <br />
          Conformément aux dispositions des articles 38 et suivants de la loi
          78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
          libertés, tout utilisateur dispose d’un droit d’accès, de
          rectification et d’opposition aux données personnelles le concernant,
          en effectuant sa demande écrite et signée, accompagnée d’une copie du
          titre d’identité avec signature du titulaire de la pièce, en précisant
          l’adresse à laquelle la réponse doit être envoyée.
          <br />
          <br />
          Aucune information personnelle de l'utilisateur du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée
          ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du
          rachat de{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          et de ses droits permettrait la transmission desdites informations à
          l'éventuel acquéreur qui serait à son tour tenu de la même obligation
          de conservation et de modification des données vis-à-vis de
          l'utilisateur du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          .
          <br />
          <br />
          Le site n'est pas déclaré à la CNIL car il ne recueille pas
          d'informations personnelles.
          <br />
          <br />
          Les bases de données sont protégées par les dispositions de la loi du
          1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
          relative à la protection juridique des bases de données.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">8. Liens hypertextes et cookies</h2>
        <p className="cg-section-text">
          Le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          contient un certain nombre de liens hypertextes vers d’autres sites,
          mis en place avec l’autorisation de{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          . Cependant,{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          n’a pas la possibilité de vérifier le contenu des sites ainsi visités
          et n’assumera en conséquence aucune responsabilité de ce fait.
          <br />
          <br />
          La navigation sur le site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          est susceptible de provoquer l’installation de cookie(s) sur
          l’ordinateur de l’utilisateur. Un cookie est un fichier de petite
          taille, qui ne permet pas l’identification de l’utilisateur, mais qui
          enregistre des informations relatives à la navigation d’un ordinateur
          sur un site. Les données ainsi obtenues visent à faciliter la
          navigation ultérieure sur le site et ont également vocation à
          permettre diverses mesures de fréquentation.
          <br />
          <br />
          Le refus d’installation d’un cookie peut entraîner l’impossibilité
          d’accéder à certains services. L’utilisateur peut toutefois configurer
          son ordinateur de la manière suivante, pour refuser l’installation des
          cookies :
          <br />
          <br />
          • Sous Internet Explorer : onglet "Outil" (pictogramme en forme de
          rouage en haut a droite) / options internet. Cliquez sur
          "Confidentialité" et choisissez "Bloquer tous les cookies". Validez
          sur "Ok".
          <br />
          • Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le
          bouton Firefox, puis aller dans l'onglet "Options". Cliquer sur
          l'onglet "Vie privée". Paramétrez les règles de conservation sur :
          utiliser les paramètres personnalisés pour l'historique. Enfin
          décochez-la pour désactiver les cookies.
          <br />
          • Sous Safari : Cliquez en haut à droite du navigateur sur le
          pictogramme de menu (symbolisé par un rouage). Sélectionnez
          "Paramètres". Cliquez sur "Afficher les paramètres avancés". Dans la
          section "Confidentialité", cliquez sur "Paramètres de contenu". Dans
          la section "Cookies", vous pouvez bloquer les cookies.
          <br />
          • Sous Chrome : Cliquez en haut à droite du navigateur sur le
          pictogramme de menu (symbolisé par trois lignes horizontales).
          Sélectionnez "Paramètres". Cliquez sur "Afficher les paramètres
          avancés". Dans la section "Confidentialité", cliquez sur
          "Préférences". Dans l'onglet "Confidentialité", vous pouvez bloquer
          les cookies.
          <br />
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          9. Droit applicable et attribution de juridiction
        </h2>
        <p className="cg-section-text">
          Tout litige en relation avec l’utilisation du site{" "}
          <a className="link-legal" href="https://flowarttag.fr">
            Flowarttag
          </a>{" "}
          est soumis au droit français. Il est fait attribution exclusive de
          juridiction aux tribunaux compétents de Paris.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">
          10. Les principales lois concernées
        </h2>
        <p className="cg-section-text">
          Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n°
          2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux
          libertés.
          <br />
          <br />
          Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie
          numérique.
        </p>
        <hr className="cg-hr" />
        <h2 className="cg-section-title">11. Lexique</h2>
        <p className="cg-section-text">
          Utilisateur : Internaute se connectant, utilisant le site susnommé.
          <br />
          Informations personnelles : « les informations qui permettent, sous
          quelque forme que ce soit, directement ou non, l'identification des
          personnes physiques auxquelles elles s'appliquent » (article 4 de la
          loi n° 78-17 du 6 janvier 1978).
        </p>
      </div>
      <Footer />
    </>
  );
}
