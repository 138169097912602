import React, { useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import "./PopUpCart.css";
import { Link } from "react-router-dom";

const PopUpCart = ({ isOpen, closePopUp }) => {
  const popUpRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popUpRef.current &&
        !popUpRef.current.contains(event.target)
      ) {
        closePopUp();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, closePopUp]);
  return (
    <div
      className={`cart-box-incomplete-fields-container ${
        isOpen ? "" : "pop-up-not-displayed"
      }`}
    >
      <div
        className={`cart-box-incomplete-fields`}
        ref={popUpRef}
      >
        <IoClose className="close-popup-x" onClick={closePopUp}/>
        <h2 className={`cart-box-text`}>
          Votre article a été ajouté à votre panier
        </h2>
        <p className={`cart-box-text`}>
          Vous pouvez continuer vos achats ou aller directement à votre panier.
        </p>
        <div
          className={`button-popup-container `}
        >
          <Link
            className="btn-black margin parallelogram-black"
            to="/shop"
            onClick={closePopUp}
          >
            <span className="skew-fix">Continuer mes achats</span>
          </Link>
          <Link
            className="btn-black margin parallelogram-black"
            to="/cart"
            onClick={closePopUp}
          >
            <span className="skew-fix">Voir mon panier</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PopUpCart;
