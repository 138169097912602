import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home/Home";
import Boutique from "./pages/Boutique/Boutique";
import Tableaux from "./pages/Boutique/Tableaux/Tableaux";
import Bombes from "./pages/Boutique/Bombes/Bombes";
import Contact from "./pages/Contact/Contact";
import Blog from "./pages/Blog/Blog";
import About from "./pages/About/About";
import CGU from "./pages/CG/CGU";
import CGV from "./pages/CG/CGV";
import Gifts from "./pages/Gifts_inspirations/Gifts";
import Cart from "./pages/Cart/Cart";
import UnpersonnalizedArticles from "./pages/Boutique/UnpersonnalizedArticles/UnpersonnalizedArticles";
import UnpersonalizedArticleDescription from "./pages/UnpersonalizedArticleDescription/UnpersonalizedArticleDescription";
import Refund from "./pages/CG/Refund";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/shop",
    element: <Boutique />,
  },
  {
    path: "/tableaux",
    element: <Tableaux />,
  },
  {
    path: "/bombes",
    element: <Bombes />,
  },
  {
    path: "/pour_decorer",
    element: <UnpersonnalizedArticles />,
  },
  {
    path: "/idees_cadeaux",
    element: <Gifts />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/cgu",
    element: <CGU />,
  },
  {
    path: "/cgv",
    element: <CGV />,
  },
  {
    path: "/refund",
    element: <Refund />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/articles/:articleId",
    element: <UnpersonalizedArticleDescription />,
  }
]);
