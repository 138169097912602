//Index file for requests made to the backend

import axios from "axios";
axios.defaults.maxContentLength = 50 * 1024 * 1024;
axios.defaults.maxBodyLength = 50 * 1024 * 1024;

const APIURL = "https://api.flowarttag.fr";

const API = axios.create({
  baseURL: APIURL,

  headers: {
    "Content-Type": "application/json", //CORS patch
  },
});

API.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const refreshToken = localStorage.getItem("refreshToken");

    const config = error?.config;

    if (error?.response?.status === 403 && !config?.sent) {
      config.sent = true;

      const result = await axios.get(`${APIURL}/refresh`, {
        headers: { authorization: `Bearer ${refreshToken}` },
      });

      if (result?.data.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.data.accessToken}`,
        };

        let accessToken = result?.data.accessToken;

        localStorage.setItem("accessToken", accessToken);
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const post = (url, formData) => API.post(url, formData);
export const get = (url) => API.get(url);
export const del = (url) => API.delete(url);

export default API;
